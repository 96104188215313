import { Toast } from "vant";

export const mixins = {
  data() {
    return {
      urlList: [
        {
          url: "https://785.g4a6z.com?dc=ZMTDL",
          icon: require("./../assets/png/logo-nut.png"),
          name: "NUT",
          time: 0,
        },
        {
          url: "https://de6dhz5dn98zc.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-haijiao.png"),
          name: "海角",
          time: 0,
        },
        {
          url: "https://djluh1nyges2j.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-yjmh.png"),
          name: "妖精",
          time: 0,
        },
        {
          url: "https://d1nzj6xwo7gxpe.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-haose.png"),
          name: "好色",
          time: 0,
        },
        {
          url: "https://d3gwm4fqn848zs.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-pf.png"),
          name: "泡芙",
          time: 0,
        },
        {
          url: "https://d277w1eco2fbtl.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-lieqi.png"),
          name: "91猎奇",
          time: 0,
        },
        {
          url: "https://d12wxf4yzd8759.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-qiyou.png"),
          name: "妻友",
          time: 0,
        },
        {
          url: "https://doxvfjipsbm1t.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-anwang.png"),
          name: "91暗网",
          time: 0,
        },
        {
          url: "https://dczch61wbe2kb.cloudfront.net?dc=ZMTDL",
          icon: require("./../assets/png/logo-naisi.png"),
          name: "奶思动漫",
          time: 0,
        },
      ], // 域名数组
      ping: 1,
      timer: null,
      showImg: true,
      addressUrl: "cao.gg",
    };
  },
  mounted() {
    this.addressUrl = window.location.host;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        this.ping++;
      }, 100);
    },
    autoTest(item, index) {
      if (this.ping <= 10) {
        item.time = this.ping * 10;
      } else {
        item.time = this.ping * 100;
      }
      if (index == this.urlList.length - 1) {
        clearInterval(this.timer);
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
    reset() {
      this.urlList = this.urlList.map((item) => {
        item.time = 0;
        return item;
      });
      this.showImg = false;
      this.$nextTick(() => {
        this.showImg = true;
      });
      this.ping = 1;
      this.init();
    },
    doCopy() {
      let req = this.addressUrl;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
